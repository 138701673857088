<template>
  <div class="regulate-page">
    <div v-show="progress.visible" class="progress-remind">
      <progress :value="progress.progress" max="100"></progress>
      <span> {{ progress.progressText }}</span>
    </div>
    <!-- 标题 -->
    <div class="regulate-header">
      <span class="header-big">下载中心/</span>
      <span class="header-small"> Download center</span>
    </div>
    <!-- 规章细则 -->
    <div class="regulate-content">
      <div
        class="regulate-list"
        v-for="item in regulateData"
        :key="item.regulateId"
      >
        <div class="regulate-download">
          <img src="http://www.jxyjzb.com/img/file/down.png" />
        </div>
        <div class="regulate-detail">
          <div
            class="R_newsTitle"
            @click="handlefileupload(item.regulateId, item.status)"
          >
            <a>{{ item.regulatetitle }}</a>
          </div>
          <div>
            <p>{{ item.regulatecontent }}</p>
            <div class="R_newsTime">
              <span>发布日期:{{ item.releasetime }}</span>
            </div>
            <div class="ragulate-downbutton">
              <el-button
                type="danger"
                size="mini"
                @click="handlefileupload(item.regulateId, item.status)"
                >下载</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 换页 -->
    <div class="regulation-pagination">
      <el-pagination
        background
        :layout="regupagelayout"
        :current-page="$store.state.xiaCurrentPage"
        :total="totalregulations"
        :page-size="regupagesize"
        @current-change="handlepagechange"
        @prev-click="handlepagechange"
        @next-click="handlepagechange"
      >
      </el-pagination>
    </div>

    <!-- 下载权限核对 -->
    <div class="file-dialog">
      <el-dialog title="权限" :visible.sync="dialogvisible">
        <div>
          <label>此文件需要核对身份才可下载</label>
          <el-input v-model="filepassword"></el-input>
        </div>
        <span slot="footer">
          <el-button @click="dialogvisible = false" size="mini"
            >取消下载</el-button
          >
          <el-button @click="handleeditorconfirm" type="danger" size="mini"
            >确认下载</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { changefiledata } from "../../utils/lchangedata.js";

export default {
  name: "Regulations",
  data() {
    return {
      currentregupage: 1,
      totalregulations: 0,
      regupagesize: 8,
      dialogvisible: false,
      regulateData: [],
      filepassword: "",
      fileID: null,
      progress: {
        visible: false,
        progress: 0,
        progressText: "等待下载中",
      },
    };
  },
  created() {
    this.$store.state.navIndex = "/regulation";
  },
  mounted() {
    axios
      .get("/api/api/file/list", {
        params: {
          pageNo: this.$store.state.xiaCurrentPage,
          pageSize: 8,
        },
      })
      .then((res) => {
        let filedatas = changefiledata(res);
        filedatas.forEach((item) => {
          this.regulateData.push(item);
        });
        this.totalregulations = parseInt(res.data.extra);
      });

    //进度条
    let progressremind = document.querySelector(".progress-remind");
    window.addEventListener("scroll", function () {
      if (window.pageYOffset - 80 > 0) {
        progressremind.style.top = "60px";
      } else {
        progressremind.style.top = "140px";
      }
    });
  },
  methods: {
    // 分页处理
    handlepagechange(index) {
      this.$store.state.xiaCurrentPage = index;
      axios.get(`/api/api/file/list?pageNo=${index}&pageSize=8`).then((res) => {
        let filedatas = changefiledata(res);
        this.regulateData.splice(0, this.regulateData.length);
        filedatas.forEach((item) => {
          this.regulateData.push(item);
        });
        this.currentregupage = index;
        // this.totalregulations = parseInt(res.data.extra);
      });
    },
    handleReguclick(id) {
      this.handlefileupload(id);
    },

    //文件下载
    handlefileupload(fileid, status) {
      //判断如果有权限则验证密码，成功再下载，
      this.fileID = fileid;
      //如果没权限直接放行
      if (status == 0) {
        this.progress.visible = true;
        axios({
          method: "get",
          url: `/api/api/file/download?id=${fileid}&password=""`,
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            let process =
              ((progressEvent.loaded / progressEvent.total) * 100) | 0;
            this.progress.progress = process;
            let progressText = `下载进度：${process}%`;
            this.progress.progressText = progressText;
          },
        }).then((res) => {
          //下载成功后对进度条处理
          setTimeout(() => {
            this.progress.visible = false;
            this.progress.progress = 0;
            this.progress.progressText = "等待下载中";
          }, 2000);
          this.$message.success("下载成功");
          let blob = new Blob([res.data]);
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          let tempName = res.headers["content-disposition"]
            .split(";")[1]
            .split("filename=")[1];
          tempName = decodeURI(tempName);
          a.download = tempName; // 设置下载下来的文件名
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else {
        this.dialogvisible = true;
      }
    },

    //有权限时的下载
    handleeditorconfirm() {
      axios
        .get(
          `/api/api/file/download?id=${this.fileID}&password=${this.filepassword}`
        )
        .then((res1) => {
          if (res1.data.description == "密码错误") {
            this.$message.error("密码错误");
          } else {
            this.progress.visible = true;
            axios({
              method: "get",
              url: `/api/api/file/download?id=${this.fileID}&password=${this.filepassword}`,
              responseType: "blob",
              onDownloadProgress: (progressEvent) => {
                let process =
                  ((progressEvent.loaded / progressEvent.total) * 100) | 0;
                this.progress.progress = process;
                let progressText = `下载进度：${process}%`;
                this.progress.progressText = progressText;
              },
            }).then((res) => {
              //下载成功后对进度条处理
              setTimeout(() => {
                this.progress.visible = false;
                this.progress.progress = 0;
                this.progress.progressText = "等待下载中";
              }, 2000);
              this.$message.success("下载成功");
              let blob = new Blob([res.data]);
              const a = document.createElement("a");
              const url = window.URL.createObjectURL(blob);
              a.href = url;
              let tempName = res.headers["content-disposition"]
                .split(";")[1]
                .split("filename=")[1];
              tempName = decodeURI(tempName);
              a.download = tempName; // 设置下载下来的文件名
              a.click();
              window.URL.revokeObjectURL(url);
              this.dialogvisible = false;
            });
          }
        });
    },
  },

  computed: {
    regupagelayout: function () {
      if (this.currentregupage == 1) {
        return "pager, next";
      } else if (
        this.currentregupage ==
        Math.ceil(this.totalregulations / this.regupagesize)
      ) {
        return "prev, pager";
      } else {
        return "prev, pager, next";
      }
    },
  },
};
</script>


<style>
/* 修改分页选中颜色 */
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #bd0c15;
  color: #ffffff;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #bd0c15;
}
</style>

<style scoped>
/* 进度条 */
.progress-remind {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 80px;
  right: 40px;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 10px rgb(146, 145, 145);
  z-index: 1000;
}
a {
  text-decoration: none;
}
.regulate-header {
  margin-top: 3rem;
  margin-bottom: 3rem;
  height: 2.5rem;
  border-bottom: 0.15rem solid rgb(189, 12, 21);
}
.header-big {
  color: rgb(189, 12, 21);
  font-size: 1.5rem;
}
.header-small {
  color: rgb(102, 102, 102);
}
/* 公告展示部分 */
.regulate-content .regulate-list {
  display: flex;
  padding-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px dotted rgb(207, 204, 204);
}
.regulate-download img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ac2a31;
}

.regulate-content .regulate-list .regulate-detail {
  width: 70%;
  text-align: left;
  font-size: 12px;
  margin-left: 10px;
}

.regulate-content .regulate-list .regulate-detail .R_newsTitle :hover {
  color: #bd0c15;
}
.regulate-content .regulate-list .regulate-detail p {
  color: grey;
  height: 50px;
  overflow: hidden;
}

.regulate-content .regulate-list .regulate-detail .R_newsListPiclist1 a {
  padding: 1px 8px;
  border: 1px solid gray;
  border-radius: 30px;
  font-size: 12px;
}
.regulate-content .regulate-list .regulate-detail .R_newsListPiclist1 :hover {
  background-color: rgb(139, 138, 138);
  color: #fff;
}
.regulation-pagination {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
.R_newsTime {
  color: #bbbbbb;
  font-size: 12px;
  margin: 10px 0;
}
.R_newsTitle a {
  font-size: 16px;
  color: #111111;
}
.ragulate-downbutton {
  text-align: right;
}
.ragulate-downbutton .el-button {
  background-color: #bd0c15;
}
.ragulate-downbutton .el-button:hover {
  background-color: #ec6d73;
}

/* 对话框的密码验证 */
.file-dialog .el-input {
  margin-top: 20px;
  width: 500px;
}
</style>